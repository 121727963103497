
$authentication-1-inner-max-width: 300px !default;
$authentication-2-inner-max-width: 380px !default;
$authentication-4-inner-max-width: 800px !default;

.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;

  .authentication-inner {
    width: 100%;
  }

  &.authentication-1,
  &.authentication-2,
  &.authentication-4 {
    align-items: center;
    justify-content: center;
  }

  &.authentication-1 .authentication-inner {
    max-width: $authentication-1-inner-max-width;
  }

  &.authentication-2 .authentication-inner {
    max-width: $authentication-2-inner-max-width;
  }

  &.authentication-3 {
    align-items: stretch;
    justify-content: stretch;

    .authentication-inner {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
      justify-content: stretch;
    }
  }

  &.authentication-4 .authentication-inner {
    max-width: $authentication-4-inner-max-width;
  }
}

.sidenavbar {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

// IE 10-11 fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .authentication-wrapper:after {
    content: '';
    display: block;
    flex: 0 0 0%;
    min-height: inherit;
    width: 0;
    font-size: 0;
  }
}
